.icon-custom {
  opacity: 1;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
