.main {
  background-color: var(--divider-color);
}

.container {
  height: var(--body-height);
  background-color: #fff;

  overflow: auto;
  margin: 0 24px 0;
  padding: 25px;
  border-radius: 4px;
  // margin-left: calc(var(--content-padding) / 2);
  // padding-left: calc(var(--content-padding) / 2);
}
